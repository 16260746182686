import React from 'react'
  import '../App.css';
  import './HeroSection.css';
  import { Link } from 'react-router-dom';
  import '../components/pages/Faqs'


  
  function HeroSection() {
      return (
          <div className='hero-container'>
            
              
              <p>Já conhece os benefícios do leite materno para o seu bebé? </p>
              
              <div className="hero-btns">
              <Link to='/FAQS' className="btns btn--outline">Saiba Mais!</Link>
                  {/* <Link href="#Products_Form">
                  <Button className='btns'
                   buttonStyle='btn--outline'
                   buttonSize='btn-large'> Productos </Button>
                   </Link> */}
                   {/* <Button className='btns'
                   buttonStyle='btn--primary'
                   buttonSize='btn-large'> Watch us <i className='far fa-play-circle' />
                     </Button> */}
              </div>
          </div>
      )
  }
  
export default HeroSection