
import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
//import { Link } from 'react-scroll';
import Checkout from './Checkout';
import { Link } from 'react-router-dom';


function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            
                
            <nav className= 'navbar'>
                <div className='navbar-container'>
                    <Link to="./" className='navbar-logo' onClick={closeMobileMenu}>
                    <img src="../images/baby_soap_logo.png" width="125" height="125" />
                    </Link> 
                
                
                    
             </div>
            </nav>
        </>
    );
}

export default Navbar; 